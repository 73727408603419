.header {
  display: flex;
  justify-content: space-between;
}

.logo {
  width: 10rem;
  height: 4rem;
}

.header-menu {
  list-style: none;
  display: flex;
  gap: 2rem;
  color: white;
}

@media screen and (max-width: 768px) {
  .header {
    display: flex;
    justify-content: space-between;
  }
  .header > :nth-child(2) {
    position: fixed;
    z-index: 99;
    right: 1rem;
  }

  .logo {
    width: 6rem;
    height: 3rem;
  }

  .header-menu {
    flex-direction: column;
    background-color: var(--appColor);
    padding: 2rem;
  }
}
