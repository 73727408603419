.Footer-container {
  position: relative;
}

.Footer-container > hr {
  border: 1px solid var(--lightgray);
}

.footer {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
  justify-content: center;
  height: 20rem;
}

.socials {
  display: flex;
  gap: 4rem;
}

.socials > img {
  height: 2rem;
  width: 2rem;
}

.Logo-f > img {
  width: 10rem;
}

.blurf1 {
  width: 26rem;
  right: 15%;
  height: 12rem;
  bottom: 0;
  filter: blur(200px);
}

.blurf2 {
  width: 26rem;
  left: 15%;
  height: 12rem;
  bottom: 0;
  filter: blur(200px);
}
